import { Controller } from '@hotwired/stimulus';
import JustValidate from 'just-validate';
import $ from 'jquery';

import { getValidationHelper } from '../src/helpers/validation_helper_factory';

export default class extends Controller {
  static targets = ['form'];

  static values = {
    validationHelper: String,
    errorMessages: { type: Object, default: {} },
  };

  connect() {
    this.helper = getValidationHelper(this.validationHelperValue);

    if (!this.helper) {
      return;
    }

    this.validationRules = this.helper.validationRules(this.errorMessagesValue);

    const form = this.formTarget;

    this.validate = new JustValidate(form, {
      validateBeforeSubmitting: true,
      errorFieldCssClass: 'invalid',
    });

    form.addEventListener('turbo:submit-end', (event) => {
      const { fetchResponse, success } = event.detail;

      if (success) {
        form.reset(); // Reset the form if the submission was successful
      } else {
        fetchResponse.responseText.then((text) => {
          const response = JSON.parse(text);
          this.validate.showErrors({ [`#${response.field}`]: response.error });
        });
      }
    });

    this.validationRules.forEach((validation) => {
      console.log($(this.formTarget).find(validation['id']));
      if ($(this.element).find(validation['id'])) {
        switch (validation['type']) {
          case 'group': {
            this.validate.addRequiredGroup(
              validation['id'],
              validation['errorMessage'] ||
                'You should select at least one option',
              validation['config'] || {},
            );
            break;
          }
          case 'field': {
            this.validate.addField(
              validation['id'],
              validation['rules'] || [{ rule: 'required' }],
              validation['config'] || {},
            );

            break;
          }
        }
      }
    });

    this.validate.onSuccess((event) => {
      event.preventDefault();

      this.validate.destroy();

      setTimeout(() => {
        form.requestSubmit();
      }, 100);
    });

    this.validate.onValidate(({ isValid, isSubmitted, fields, groups }) => {
      const formId = $(this.formTarget).attr('id');
      $(`button[type=submit][form=${formId}]`).attr(
        'disabled',
        !isValid && isSubmitted,
      );

      Object.values(groups).forEach((group) => {
        if (!group.touched && !isSubmitted) return;

        if (group.isValid) {
          $(group.groupElem)
            .find('button.selector')
            .first()
            .removeClass('invalid');
        } else {
          $(group.groupElem)
            .find('button.selector')
            .first()
            .addClass('invalid');
        }
      });
    });
  }
}
