import NewSupportRequestValidationHelper from './NewSupportRequestValidationHelper';
import NewGrantorSupportRequestValidationHelper from './NewGrantorSupportRequestValidationHelper';
import EnquiryFormValidationHelper from './EnquiryFormValidationHelper';
import NewUserValidationHelper from './NewUserValidationHelper';
import NewPermissionValidationHelper from './NewPermissionValidationHelper';
import NewPreferenceValidationHelper from './NewPreferenceValidationHelper';
import NewCardValidationHelper from './NewCardValidationHelper';

export function getValidationHelper(className) {
  switch (className) {
    case 'NewSupportRequestValidationHelper':
      return new NewSupportRequestValidationHelper();
    case 'NewGrantorSupportRequestValidationHelper':
      return new NewGrantorSupportRequestValidationHelper();
    case 'EnquiryFormValidationHelper':
      return new EnquiryFormValidationHelper();
    case 'NewUserValidationHelper':
      return new NewUserValidationHelper();
    case 'NewPermissionValidationHelper':
      return new NewPermissionValidationHelper();
    case 'NewPreferenceValidationHelper':
      return new NewPreferenceValidationHelper();
    case 'NewCardValidationHelper':
      return new NewCardValidationHelper();
    default:
      return null;
  }
}
