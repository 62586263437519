import dayjs from 'dayjs';
// import * as d3 from 'd3';

import BaseChartsController from './base_charts_controller.js';

import {
  MAX_DAYS_IN_MONTH,
  DATE_FORMAT_BY_MONTHS,
  DATE_FORMAT,
  DATE_RANGE_TICK,
  getDateTick,
} from './constants.js';

export default class extends BaseChartsController {
  get defaultTimeSeriesLayoutConfig() {
    return {
      ...this.defaultLayoutConfig,
      yaxis: {
        ...this.defaultLayoutConfig.yaxis,
        minor: {
          tickmode: 'auto',
        },
        rangemode: 'tozero',
        nticks: this.maxYValue > 10 ? 10 : this.maxYValue + 1,
        // only integer
        tickformat: ',d',
        ticklabeloverflow: 'allow',
        hoverformat: this.isRelativeMode ? '.1f' : '.0f',
      },
      xaxis: {
        ...this.defaultLayoutConfig.xaxis,
        title: {
          ...this.defaultAxesConfig.title,
          text: 'Date',
        },
        tickformat: this.timePeriodValue === '12M' ? '%b \n %Y' : undefined,
        hoverformat: undefined,
        type: 'date',
        dtick: getDateTick(this.dateRangeTickValue),
      },
    };
  }

  get showTimeRange() {
    return !!this.optionsValue.time_range;
  }

  get maxYValue() {
    if (this.hasTraces) {
      const sumOfTraces = this.yAxis.map((el) =>
        Object.values(el).reduce((sum, cValue) => sum + cValue, 0),
      );

      return Math.max(...sumOfTraces);
    }

    return Math.max(...this.yAxis);
  }

  connect() {
    super.connect();

    this.addMissingDataToAxes();
  }

  // to handle the case where there is not enought data in the DB, but we still have the time_period filter set to 12M, 6M or 3M
  //
  // for example when the time_period=12M we need to make sure the xAxis array starts with Time.now - 12 months (1 year)
  // so that the x axis labels start at the correct date even if the bars are empty
  addMissingDataToAxes() {
    this.xAxis = this.xAxisValue;
    this.yAxis = this.yAxisValue;

    let subtractCount = 1;
    let subtractValue = 'month';
    let isBefore = 'month';
    let dateFormat = DATE_FORMAT;

    switch (this.timePeriodValue) {
      case '1W': {
        subtractValue = 'day';
        subtractCount = 6;
        isBefore = 'day';
        break;
      }
      case '3M': {
        subtractCount = 3;
        break;
      }
      case '6M': {
        subtractCount = 6;
        break;
      }
      case '12M': {
        subtractCount = 1;
        subtractValue = 'year';
        dateFormat = DATE_FORMAT_BY_MONTHS;
        break;
      }
    }

    const startDate = dayjs()
      .subtract(subtractCount, subtractValue)
      .format(DATE_FORMAT);

    if (dayjs(startDate).isBefore(this.xAxis[0], isBefore)) {
      // put the startDate data to the start of the xAxis array
      this.xAxis.unshift(startDate);

      let emptyData = this.yAxis[0];

      if (typeof emptyData === 'object') {
        Object.keys(emptyData).forEach((i) => {
          emptyData[i] = 0;
        });
      } else {
        emptyData = 0;
      }

      // put an empty Hash to the top of the yAxis array
      this.yAxis.unshift(emptyData);
    }

    const endDate = dayjs().format(DATE_FORMAT);

    if (dayjs(this.xAxis[this.xAxis.length - 1]).isBefore(endDate, isBefore)) {
      // put the endDate data to the end of the xAxis array
      this.xAxis.push(endDate);
      this.yAxis.push(this.yAxis[this.yAxis.length - 1]);
    }
    this.xAxis = this.xAxis.map((i) => dayjs(i).format(dateFormat));
  }

  // direction = 'x' OR 'y'
  getAxisConfigByDirection(direction) {
    return {
      ...this.defaultAxesConfig,
      hoverformat: undefined,
      showgrid: direction === 'y',
      title: {
        ...this.defaultAxesConfig.title,
        text: this.optionsValue?.[`${direction}_axis_label`] || '',
      },
      ticksuffix:
        this.optionsValue?.[`${direction}_axis_tick_suffix`] ||
        (this.isRelativeMode &&
          (direction === 'x'
            ? !this.isDefaultOrientation
            : this.isDefaultOrientation))
          ? '%'
          : '',
    };
  }

  // range = month OR week OR day
  // groupDatesByRange(range) {
  //   const xAxis = this.xAxisValue.map((i) =>
  //     dayjs(i).startOf(range).format(DATE_FORMAT),
  //   );
  //   const uniqXAxis = xAxis.filter(
  //     (v, index) => !dayjs(v).isSame(xAxis[index + 1]),
  //   );

  //   return { x: uniqXAxis };
  // }

  // detaPickerValue can be 30d, 60d, 90d, or all
  // getDateRange(detaPickerValue) {
  //   if (detaPickerValue === 'reset') {
  //     return [this.xAxis[0], ...this.xAxis.slice(-1)];
  //   }

  //   const dates = this.xAxisValue
  //     .filter((i) => {
  //       let limit;
  //       switch (detaPickerValue) {
  //         case '30d': {
  //           limit = dayjs().subtract(1, 'month').startOf('month');
  //           break;
  //         }
  //         case '60d': {
  //           limit = dayjs().subtract(2, 'month').startOf('month');
  //           break;
  //         }
  //         case '90d': {
  //           limit = dayjs().subtract(3, 'month').startOf('month');
  //           break;
  //         }
  //         default: {
  //           limit = dayjs().subtract(1, 'year').startOf('month');
  //           break;
  //         }
  //       }
  //       const diff = dayjs(i).diff(limit, 'day');

  //       return diff > 0;
  //     })
  //     .map((i) => dayjs(i).format(DATE_FORMAT));

  //   return [dates[0], ...dates.slice(-1)];
  // }

  // getAxisWithoutDublicates(xAxis, yAxis) {
  //   const duplicatedIndexes = [];
  //   const uniqX = xAxis.filter((v, index) => {
  //     const isSame = moment(v).isSame(xAxis[index + 1]);

  //     if (isSame) {
  //       duplicatedIndexes.push(index);
  //     }

  //     return !isSame;
  //   })

  //   const uniqY =  duplicatedIndexes.length ? yAxis.filter((_, index) => {
  //     return !duplicatedIndexes.includes(index);
  //   }) : yAxis;

  //   return { x: uniqX, y: uniqY }
  // }

  calculateBarWidthAndOffsets() {
    const barWidth =
      DATE_RANGE_TICK.day * MAX_DAYS_IN_MONTH -
      DATE_RANGE_TICK.day * this.xAxis.length;

    this.labelOffsets = [];
    let offsetDaysCount = 0;

    const offsets = this.xAxis.map((currentDate, index) => {
      const prevDate = this.xAxis[index - 1];
      const daysInMonth = dayjs(currentDate).daysInMonth();
      const daysInPrevMonth =
        index === 0 ? null : dayjs(prevDate).daysInMonth();

      if (daysInPrevMonth && daysInPrevMonth !== MAX_DAYS_IN_MONTH) {
        offsetDaysCount =
          offsetDaysCount + (MAX_DAYS_IN_MONTH - daysInPrevMonth);

        this.labelOffsets.push(
          offsetDaysCount + (MAX_DAYS_IN_MONTH - daysInMonth),
        );
        return (
          -barWidth / 2 +
          DATE_RANGE_TICK.day *
            (offsetDaysCount + (MAX_DAYS_IN_MONTH - daysInMonth))
        );
      }

      this.labelOffsets.push(offsetDaysCount);

      return -barWidth / 2 + DATE_RANGE_TICK.day * offsetDaysCount;
    });

    return { barWidth, offsets };
  }
}
